import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { PreEnrollmentMemberService } from 'src/app/member/services/preEnrollment-member.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CPMemberService } from '../../services/CPMember.service';
import { Policy } from '../../models/policy.model';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsentComponent } from './consent/consent.component';
import { RoleService } from 'src/app/shared/services/role.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cp-member',
  templateUrl: './cp-member.component.html',
  styleUrls: ['./cp-member.component.scss'],
})
export class CpMemberComponent extends BaseComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  subscription: Subscription;
  role: any;
  policyList: Policy[] = [];
  currentPolicy: Policy;
  policyNumber: string;
  isHr: boolean;
  isMember: boolean;
  isHrView = false;
  modalOptions: NgbModalOptions = {};

  constructor(
    injector: Injector,
    private readonly authService: AuthService,
    private policyService: CPPolicyService,
    private cpMemberService: CPMemberService,
    private modalService: NgbModal,
    private roleService: RoleService,
    private router: Router
  ) {
    super(injector);
  }

  async ngOnInit() {
    super.ngOnInit();
    // check for CPMember role
    // this.memberFunc(); commented, because this line was logging an error initially and this method is already called later
    this.role = this.authService.role;
    //the user wants to be redirected to the e-cards page on login instead of the profile page
    this.policyService.currentCorporate.subscribe(corporate => {
      console.log(corporate.id);
      if(corporate.isMultiLingual){
        this.loadDevnagriScript();
      }
      if (corporate.id == 5804) {
        this.router.navigate(['cp-member/e-cards']);
      }
    });
    //If consent is not provided then the consent modal will open to ask for consent
    this.roleService.getMemberConsent().then(res => {
      console.log(res);
      if (!res.consent) {
        this.modalOptions.backdrop = 'static';
        this.modalOptions.keyboard = false;
        this.modalOptions.size = 'lg';
        const modalRef = this.modalService.open(ConsentComponent, this.modalOptions);
      }
    });

    await this.cpMemberService.IsMemberOrHr();

    await this.policyService.getMemberHrPolicies().then(p => {
      const unsortedPolicies = p[0].policies;
      const sortedPolicies = this.sortPolicies(unsortedPolicies);
      this.policyService.allPolicies.next(sortedPolicies);
      this.policyService.policies.next(sortedPolicies);
      this.policyService.currentPolicy.next(sortedPolicies[0]);
    });

    this.cpMemberService.isHrView.subscribe(v => {
      this.isHrView = v;
    });

    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
      this.policyNumber = this.currentPolicy.policyNumber;

      this.isHr = this.cpMemberService.isHr;
      this.isMember = this.cpMemberService.isMember;
      if (this.policyNumber !== undefined && this.isHr === true && this.isHrView) {
        this.hrFunc();
      }
      if (this.policyNumber !== undefined && this.isMember === true && this.currentPolicy.isMemberEnrolled) {
        this.memberFunc();
      }
    });
  }

  loadDevnagriScript(){
      if(!document.getElementById('dota_js')){
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'dota_js';
        // script.src = 'https://dns.devnagri.com/dota.js'; old url for dota.js
        script.src = 'https://proxy.devnagri.dev/dota.js';
        script.async = true;
        script.onload = () => {
          console.log("External script loaded");
          this.loadDevnagriInlineScript();
        };
        script.onerror = (error) => {
          console.log("Error loading the external script");
        }
        document.body.appendChild(script);
      }
  }

  loadDevnagriInlineScript(){
    if(!document.getElementById('dota_init')){
      const script2 = document.createElement('script');
      script2.id = 'dota_init';
      // devnagri_15e34516702c11ef86024a279c6d377c  old apiKey
      script2.text = `
          var devnagri =
          Devnagri.initializeTranslation({
          apiKey: "devnagri_e9b839527c9b11efbb8902426003d50a",
          default_lang_code: "en"
        });
      `;
      document.body.appendChild(script2);
    }
  }

  sortPolicies(policies) {
    policies.sort(function compare(a, b) {
      const dateA = new Date(a.riskStartDate);
      const dateB = new Date(b.riskStartDate);
      return <any>dateB - <any>dateA;
    });
    policies.sort(policy => {
      return policy.isActive === false ? 0 : policy ? -1 : 1;
    });
    const activePolicyArray = [];
    const inactivePolicyArray = [];
    policies.forEach(policy => {
      if (policy.isActive) {
        activePolicyArray.push(policy);
      } else {
        inactivePolicyArray.push(policy);
      }
    });
    activePolicyArray.reverse();
    policies = [];
    policies = [...activePolicyArray, ...inactivePolicyArray];

    return policies;
  }

  async hrFunc() {
    await this.cpMemberService
      .getHRProfile()
      .then(x => {
        this.cpMemberService.hrDetails.next(x);
      })
      .catch(x => {
        console.log('error');
      });

    await this.cpMemberService
      .UpdateHrSeenNotification()
      .then(x => {
        console.log(x);
      })
      .catch(x => {
        console.log('error');
      });

    await this.cpMemberService
      .GetUnSeenHrNotificationCount()
      .then(x => {
        console.log(x);
      })
      .catch(x => {
        console.log('error');
      });
  }

  async memberFunc() {
    await this.cpMemberService
      .getMemberFamily(this.currentPolicy.policyNumber)
      .then(x => {
        console.log(x);
      })
      .catch(x => {
        console.log('error');
      });

    await this.cpMemberService
      .UpdateMemberSeenNotification(this.currentPolicy.policyId)
      .then(x => {
        console.log(x);
      })
      .catch(x => {
        console.log('error');
      });

    await this.cpMemberService
      .GetUnSeenMemberNotificationCount()
      .then(x => {
        console.log(x);
      })
      .catch(x => {
        console.log('error');
      });
  }

  toggleSidebar(event) {
    event.currentTarget.offsetParent.classList.toggle('close-sidebar');
    event.currentTarget.classList.toggle('closed');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
