<!-- 1. View Policy Coverage
2. Addition of dependents(HR/broker/sm)
3. Track Claims
4. View E-Card
5. Network Hospital Tracker
6. Download Forms
7. Intimate Claims
8. Contact Us
9. FAQs
10. Wellness - RHealth Assist, RHeath Circle, RHeath Beat, Health-o-pedia, HRA, and Value deals(these 6 options will be in a sub-menu and can be individually enabled and disabled).  -->
<div class="error-msg" *ngFor="let error of errorList">
  <p>{{ error.message }}</p>
</div>
<div *ngIf="features">
  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.viewPolicyCoverage"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>View Policy Coverage</span>
      </label>
    </div>
  </div>
  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.trackClaims"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Track Claims</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.gpa"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Intimate Accidental Claim</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.mobileClaim"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Intimate Mobile Claim</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.attendance"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Attendance</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.viewECard"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>View E-Card</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.nomineeEnroll"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Nominee Enroll</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.additionOfDependents"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Endorsement</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.networkHospitalTracker"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Network Hospital Tracker</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.downloadForms"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Download Forms</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.intimateClaims"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Intimate Claims</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.intimatePACIClaims"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        
        <span>Intimate PACI Claims</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.contactUs"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Contact Us</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input type="checkbox" [(ngModel)]="features.faq" [disabled]="!isEditFeature" class="custom-control-input" />
        <span>FAQs</span>
      </label>
    </div>
  </div>

  <div class="form-group">
    <div class="custom-checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="features.wellness"
          (change)="checkWellnessValue()"
          [disabled]="!isEditFeature"
          class="custom-control-input"
        />
        <span>Wellness</span>
      </label>
    </div>
    <div [hidden]="!features.wellness" class="form-group pl-3 ml-1">
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.rHealthAssist"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>RHealth Assist</span>
        </label>
      </div>
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.rHealthCircle"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>RHeath Circle</span>
        </label>
      </div>
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.rHealthBeat"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>RHeath Beat</span>
        </label>
      </div>
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.healthOPedia"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>Health-o-pedia</span>
        </label>
      </div>
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.hra"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>HRA</span>
        </label>
      </div>
      <div class="custom-checkbox">
        <label>
          <input
            type="checkbox"
            [(ngModel)]="features.valueDeals"
            (change)="checkWellnessValue()"
            [disabled]="!isEditFeature"
            class="custom-control-input"
          />
          <span>Value deals</span>
        </label>
      </div>
    </div>
  </div>

  <!-- <div class="w-100">
    <button (click)="save()" class="btn btn-primary">Save</button>
  </div> -->
  <div class="row">
    <div class="col">
      <div class="mt-4">
        <button (click)="save()" class="btn btn-primary px-4" [hidden]="!noFeatureAdded" [disabled]="disableSaveButton">
          Add Features
        </button>
        <button
          (click)="save()"
          class="btn btn-primary px-4"
          [hidden]="noFeatureAdded || !isEditFeature"
          [disabled]="disableSaveButton"
        >
          Save Changes
        </button>
        <button (click)="cancel()" [hidden]="noFeatureAdded || !isEditFeature" class="btn btn-link ml-2 text-secondary">
          Cancel
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" style="display: inline-block;">
      <div class="mt-4">
        <button (click)="isEditFeature = true" [hidden]="noFeatureAdded || isEditFeature" class="btn btn-primary px-4">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>
