import { IntimateClaimUploadDocuments } from './intimate-claim-upload-documents.model';

export class IntimateClaim {
  InsuredName: string;
  UHIDNumber: string;
  EmployeeID: string;
  MobileNumber: string;
  RelationShip: string;
  EmailID: string;
  InsuredUHID: string;
  InsuredEmailId: string;
  PatientName: string;
  PatientUHID: string;
  PatientMobileNo: string;
  DateOfAdmission: string;
  IntimatorMobileNo: string;
  PolicyName: string;
  PolicyNumber: string;
  PolicyStartDate: string;
  PolicyEndDate: string;
  IntimatorName: string;
  IntimatorEmailId: string;
  IntimationType: string;
  AdmissionDate = '';
  DischargeDate = '';
  DoctorName = '';
  Age: number;
  DiagnosisDetails = '';
  ClaimedAmount: number;
  ClDetailId = '';
  HospitalId: number;
  HospitalName = '';
  Address1: string;
  Address2: string;
  Address3: string;
  State: string;
  City: string;
  District: string;
  Pincode: number;
  Remarks: string;
  DisallowedAmount:number;
  ClaimAmount:number;
  ServiceCode:string;
  ServiceType:string;
  IntimatorRelationship : string;
  ReceivedMode : string;
  AbhaId: string;
  AbhaAddress: string;
  DocumentList: IntimateClaimUploadDocuments[] = [];
}
